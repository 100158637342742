<template>
  <el-form>
    <el-form-item class="sellerItem" v-for="(i, n) in cache.productSalesmanList" :key="n">
      <span style="margin:0 10px">人员</span>
      <el-select filterable placeholder="请选择人员或者输入搜索" :value="i.oaId" @input="updateSeller({ index: n, value: $event })">
        <el-option
          v-for="(seller, sellerIndex) in sellerList"
          :key="sellerIndex"
          :label="seller.trueName"
          :value="seller._id"
        ></el-option>
      </el-select>
      <span style="margin:0 10px">分成比例</span>
      <el-input-number
        placeholder="分成比例"
        :value="i.saleRatio"
        @input="updateSellerRatio({ index: n, value: $event })"
        :min="0"
        :max="100"
        controls-position="right"
      >
      </el-input-number>
      <span style="margin:0 10px">%</span>
      <el-button @click="deleteSeller(n)" icon="el-icon-delete" type="danger" plain size="mini"></el-button>
    </el-form-item>
    <el-form-item>
      <el-button type="success" plain size="mini" @click="addNewSeller">添加销售</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  props: {
    foo: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    bar: 'bar'
  }),
  computed: {
    ...mapGetters({
      cache: 'product/partner/cache',
      sellerList: 'product/basic/sellerList'
    })
  },
  watch: {},
  methods: {
    ...mapMutations('product/partner', ['addNewSeller', 'deleteSeller','updateSellerRatio']),
    ...mapActions({
      getSellerList: 'product/basic/getSellerList',
      updateSeller: 'product/partner/handleUpdateSeller'
    })
  },
  created() {},
  mounted() {
    this.getSellerList()
  }
}
</script>

<style lang="less" scoped>
.componentContainer {
  width: 100%;
}
</style>
