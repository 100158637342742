<route>
{
  "meta": {
    "noTransition": true
  }
}
</route>

<template>
  <com-layout class="page">
    <el-card v-loading="loading">
      <el-form label-width="10em">
        <!-- 限时商品在活动进行限购 -->
        <el-form-item label="售卖时间" required>
          <el-date-picker
            value-format="timestamp"
            :value="saleDateRange"
            @input="updateSaleDateRange"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="核销时间" required v-if="saleType !== 5">
          <el-date-picker
            value-format="timestamp"
            :value="writeOffDateRange"
            @input="handleWriteOffTimeChange"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
          <div v-if="saleType === 2" class="tip">
            因外接商品的特殊性，核销码的状态扭转可以发生在核销时间结束后14天内。
          </div>
        </el-form-item>
        <el-form-item label="服务说明" required>
          <el-tag
            :key="tag.id"
            v-for="tag in pickedServiceTagList"
            closable
            :disable-transitions="false"
            @close="deleteServiceTag(tag)"
            style="margin: 5px"
          >
            {{ tag.serviceContent }}
          </el-tag>
          <template v-if="pickedServiceTagList.length < 3">
            <el-popover v-model="isShowServiceTagPicker" placement="right" width="400" trigger="manual">
              <el-tag
                :key="tag.id"
                v-for="tag in serviceTagList"
                :disable-transitions="false"
                @click="
                  addServiceTag(tag)
                  isShowServiceTagPicker = false
                "
                style="margin: 5px"
              >
                {{ tag.serviceContent }}
              </el-tag>
              <el-button slot="reference" type="success" size="mini" @click="isShowServiceTagPicker = true"
                >+ 添加服务说明</el-button
              >
            </el-popover>
          </template>
          <bm-tip>勾选的项将以图标的方式呈现，可以直接地提醒用户（最多可添加3个）</bm-tip>
        </el-form-item>
        <!-- 限时商品在活动进行限购 -->
        <el-form-item label="商品限购" v-if="saleType !== 4">
          <el-input-number
            :value="cache.buyLimit"
            @input="updateCache({ key: 'buyLimit', value: $event })"
            :min="0"
            controls-position="right"
          ></el-input-number>
          <bm-tip>0为不限购数量</bm-tip>
        </el-form-item>
        <el-form-item label="基础浏览数" prop="baseVirtualViewCount">
          <el-input-number
            :value="cache.baseVirtualViewCount"
            @input="updateCache({ key: 'baseVirtualViewCount', value: $event })"
            :min="0"
            controls-position="right"
          ></el-input-number>
          <bm-tip>设置商品基础浏览数，新的浏览人数将在此基础上进行叠加</bm-tip>
        </el-form-item>

        <el-form-item label="下单弹出购买须知">
          <el-switch
            :value="cache.switchShowPurchaseInstructions"
            :inactive-value="0"
            :active-value="1"
            @change="updateCache({ key: 'switchShowPurchaseInstructions', value: $event })"
          ></el-switch>
        </el-form-item>
        <!-- 限时商品默认就是上架 -->
        <el-form-item label="商品上架开关" v-if="saleType !== 4">
          <el-switch
            :value="cache.switchPublish"
            @change="updateCache({ key: 'switchPublish', value: $event })"
            :inactive-value="0"
            :active-value="1"
          ></el-switch>
        </el-form-item>
        <el-form-item label="显示不可退款协议">
          <el-switch
            :value="cache.switchRefundAgreement"
            @input="updateCache({ key: 'switchRefundAgreement', value: $event })"
            :inactive-value="0"
            :active-value="1"
          ></el-switch>
        </el-form-item>
        <el-form-item label="合作方式" v-if="!workOrderId">
          <el-checkbox-group
            :value="cache.cooperationTypeList"
            @input="updateCache({ key: 'cooperationTypeList', value: $event })"
          >
            <el-checkbox :label="1">广告置换</el-checkbox>
            <el-checkbox :label="2">广告分成</el-checkbox>
            <el-checkbox :label="3">商城置换</el-checkbox>
            <el-checkbox :label="4">商城分成</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="关联版面" v-if="!workOrderId">
          <comPromoSection></comPromoSection>
        </el-form-item>
        <el-form-item label="关联销售" v-if="!workOrderId">
          <comSellerSection></comSellerSection>
        </el-form-item>
        <el-form-item label="退款设置">
          <el-radio-group :value="cache.refundType" @input="updateCache({ key: 'refundType', value: $event })">
            <el-radio :label="1">不支持退款</el-radio>
            <el-radio :label="2" :disabled="saleType === 2 || saleType === 3">支持退款</el-radio>
            <el-radio :label="3" :disabled="saleType === 2 || saleType === 3">过期不退款</el-radio>
          </el-radio-group>
          <bm-tip>*注：过期不退款：订单未过期，用户可申请退款，过期后无申请退款按钮</bm-tip>
        </el-form-item>

        <el-form-item label="二维码设置">
          <el-radio-group
            :value="cache.posterQrCodeSet"
            @input="updateCache({ key: 'posterQrCodeSet', value: $event })"
          >
            <el-radio :label="1">公众号</el-radio>
            <el-radio :label="2">小程序</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="开售提醒订阅消息">
          <el-radio-group :value="cache.onSaleNotify" @input="updateCache({ key: 'onSaleNotify', value: $event })">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分享素材">
          <el-switch
            :value="cache.ffffffffff"
            @change="setShareContent"
            @input="updateCache({ key: 'ffffffffff', value: $event })"
            :inactive-value="0"
            :active-value="1"
          ></el-switch>

          <bm-tip>如果关闭，则使用商品名称和相册作为分享文案和海报图片</bm-tip>
        </el-form-item>
        <el-form-item v-show="cache.ffffffffff === 1" label="分享文案" required>
          <el-input
            :value="cache.shareContent"
            @input="updateCache({ key: 'shareContent', value: $event })"
            maxlength="120"
            type="textarea"
            show-word-limit
            :autosize="{ minRows: 2, maxRows: 4 }"
            style="width: 350px"
            placeholder="分享文案"
          ></el-input>
        </el-form-item>
        <el-form-item v-show="cache.ffffffffff === 1" label="分享图片" required>
          <bm-upload
            type="commodity"
            :reduceResolution="true"
            :value="shareImageList"
            @input="updateShareImageList"
            :limit="1000"
          ></bm-upload>
          <div style="font-size: 12px; color: #999">建议上传比例1:1 ，尺寸在750px ~ 1200px 之间的图片</div>
        </el-form-item>
        <el-form-item label="分享海报">
          <el-radio-group
            :value="cache.posterTemplateId"
            @input="updateCache({ key: 'posterTemplateId', value: $event })"
          >
            <el-tooltip placement="right" effect="light" v-for="item in postTempList" :key="item._id">
              <img slot="content" style="width: 300px" :src="item.previewUrl" />
              <el-radio :label="item._id">{{ item.name }}</el-radio>
            </el-tooltip>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <el-button @click="handleSubmit" type="primary">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </com-layout>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import editMixin from './__com__/editMixin'
import comPromoSection from './__com__/promoSection'
import comSellerSection from './__com__/sellerSection'

export default {
  data: () => ({
    foo: 'bar',
    isShowServiceTagPicker: false,
    commodityData: {}
  }),
  mixins: [editMixin],
  components: { comPromoSection, comSellerSection },
  computed: {
    ...mapGetters('product/partner', [
      'saleDateRange',
      'writeOffDateRange',
      'pickedServiceTagList',
      'saleType',
      'writeOffTimeEnd',
      'shareImageList'
    ]),
    ...mapGetters({
      serviceTagList: 'product/basic/serviceTagList',
      postTempList: 'product/basic/postTempList'
    }),
    workOrderId() {
      return this.cache.oaIssueId && isNaN(this.cache.oaIssueId) && !this.$route.query.copy
    }
  },
  methods: {
    handleWriteOffTimeChange(e) {
      if (this.$route.query.copy) {
        this.updateWriteOffDateRange(e)
        return
      }
      let text, color
      if (e && this.writeOffTimeEnd !== 0) {
        if (this.writeOffTimeEnd > e[1]) {
          text = '核销结束时间已提前，是否对已产生的订单（未核销） 同步？'
          color = '#e71414'
        } else {
          text = '核销时间发生变化,是否对已产生的订单（未核销）同步 ？'
          color = '#606266'
        }

        const h = this.$createElement
        this.$msgbox({
          title: '提示',
          message: h('p', null, [h('span', { style: `color: ${color}` }, text)]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              this.updateSyncWriteOffTime(1)
            }
            done()
          }
        })
      }
      this.updateWriteOffDateRange(e)
    },
    ...mapMutations('product/partner', [
      'deleteServiceTag',
      'addServiceTag',
      'updateWriteOffDateRange',
      'updateSyncWriteOffTime',
      'updateShareImageList',
      'setShareContent',
      'updateSaleDateRange'
    ]),
    ...mapActions({
      getServiceTagList: 'product/basic/getServiceTagList',
      getPostTemp: 'product/basic/getPostTemp',
      submit: 'product/partner/submit'
    }),
    handleAddTag(tag) {
      this.addServiceTag(tag)
      this.isShowServiceTagPicker = false
    }
  },
  created() {},
  mounted() {
    this.loading = true
    Promise.all([this.getServiceTagList(), this.getPostTemp(), this.getData()]).finally(() => {
      this.loading = false
    })
    if (this.saleType !== 1 && this.saleType !== 4 && !this.cache.productId) {
      this.updateCache({ key: 'refundType', value: 1 })
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  width: 100%;
}
.tip {
  margin-left: 10px;
  font-size: 12px;
  color: var(--text-grey);
}
</style>
