<template>
  <el-form>
    <el-form-item class="sellerItem" v-for="(i, n) in cache.productPromotionList" :key="n">
      <el-cascader
        :options="promoList"
        :value="i.promotionAccountId"
        @input="updatePromo({ index: n, key: 'promotionAccountId', value: $event })"
        :props="{ checkStrictly: true, value: 'id', label: 'promotionAccountName' }"
        clearable
        filterable
      ></el-cascader>
      <el-select
        filterable
        style="margin: 0 10px"
        placeholder="请选择版次"
        :value="i.editionNumber"
        @input="updatePromo({ index: n, key: 'editionNumber', value: $event })"
      >
        <el-option v-for="i in 8" :key="i" :label="i" :value="i.toString()"></el-option>
      </el-select>
      <el-date-picker
        :value="i.promotionTime"
        @input="updatePromo({ index: n, key: 'promotionTime', value: $event })"
        value-format="timestamp"
        type="datetime"
        placeholder="选择日期时间"
      >
      </el-date-picker>
      <el-button
        style="margin-left: 10px"
        @click="deletePromo(n)"
        icon="el-icon-delete"
        type="danger"
        plain
        size="mini"
      ></el-button>
    </el-form-item>
    <el-form-item>
      <el-button type="success" plain size="mini" @click="addNewPromo">添加版面</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  props: {
    foo: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    bar: 'bar'
  }),
  computed: {
    ...mapGetters({
      cache: 'product/partner/cache',
      promoList: 'product/basic/promoList'
    })
  },
  watch: {},
  methods: {
    ...mapMutations('product/partner', ['addNewPromo', 'deletePromo', 'updatePromo']),
    ...mapActions({
      getPromoList: 'product/basic/getPromoList'
    }),
    changeAccountId() {
      this.cache.productPromotionList.forEach((item, index) => {
        if (typeof item.promotionAccountId == 'string ') {
          this.promoList[0].children.forEach(i => {
            if (item.promotionAccountId == i.promotionAccountId) {
              this.updatePromo({ index: index, key: 'promotionAccountId', value: ['0', i.promotionAccountId] })
            }
          })
          this.promoList[1].children.forEach(i => {
            if (item.promotionAccountId == i.promotionAccountId) {
              this.updatePromo({ index: index, key: 'promotionAccountId', value: ['1', i.promotionAccountId] })
            }
          })
        }
      })
    }

    //

    // })
  },
  created() {},
  mounted() {
    this.getPromoList()
    this.changeAccountId()
  }
}
</script>

<style lang="less" scoped>
.componentContainer {
  width: 100%;
}
</style>
